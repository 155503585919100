import { GDPSStatsProps } from "../../types/props";
import "./GDPSStat.scss";

export default function GDPSStat(props:GDPSStatsProps) {
    return (
        <li className="gdps-stats-item">
            <h3>{props.title}</h3>
            <p>{props.count}</p>
        </li>
    );
}