import { WeareItemProps } from "../../types/props";
import "./WeareItem.scss";

export default function WeareItem(props:WeareItemProps) {
    return (
        <li className="weare-item">
            <img src={`./img/icons/${props.img}.svg`} alt="" />
            <div className="weare-item-text">
                <h3>{props.title}</h3>
                <p>{props.text}</p>
            </div>
        </li>
    );
}