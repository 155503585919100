import { LevelProps } from "../../types/props";
import getRandomInt from "../../utils/getRandomInt";
import { useDemonWindow } from "../../hooks/openLevelWindow";
import "./Level.scss";

export default function Level(props:LevelProps) {
    const { openWindow } = useDemonWindow();
    
    return (
        <li className="level" onClick={() => openWindow(props)}>
            <img src={`./img/levels/${props.levelid}.png?${getRandomInt(100, 999)}`} alt="demon" />
            <div className="level-text">
                <h3>#{props.position} {props.levelname}</h3>
                <p>By {props.author}</p>
            </div>
        </li>
    );
}