import "../Home/Home.scss";

export default function Download() {
    return (
        <main>
            <div className="container">
                <div className="download" id="download" style={{marginTop: 200}}>
                    <div className="download-img">
                        <img src="./img/home/dwnl.png" alt="" />
                    </div>
                    <div className="download-text">
                        <h2>Download</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde eos maiores veritatis distinctio sequi magnam vero totam ipsum, expedita incidunt explicabo enim quam voluptates? Vitae nulla totam ratione voluptatibus nihil?</p>
                        <div className="dwnl-btns">
                            <a href="./ReLegacyX.apk">Download APK v1.51</a>
                            <a href="./relegacy.ipa">Download IPA v1.4</a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}