import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderLink from "./HeaderLink";
import "./Header.scss";

export default function Header() {
    const navigate = useNavigate();
    const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
    const pathname = useLocation();

    const toggleMenu = () => {
        setIsMenuActive(pr => !pr);
    }

    useEffect(() => {
        setIsMenuActive(false);
    }, [pathname]);

    return (
        <header>
            <div className="container">
                <div className="header-body">
                    <div className="logo">
                        <Link to="/">
                            <img src="./img/logo/main.png" alt="" />
                            <h3>ReLegacy X</h3>
                        </Link>
                    </div>
                    <div className={`hamburger ${isMenuActive ? 'active' : ''}`} onClick={toggleMenu}>
                        <span></span>
                    </div>
                    <nav className={`header-nav ${isMenuActive ? 'active' : ''}`}>
                        <ul className="header-list">
                            <HeaderLink text="Home" route="/" />
                            <HeaderLink text="Demonlist" route="/demonlist" />
                            <HeaderLink text="News" route="/news" />
                        </ul>
                        <button type="button" onClick={() => navigate('/download')}>Download</button>
                    </nav>
                </div>
            </div>
        </header>
    );
}