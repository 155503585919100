import React, { createContext, useContext, useState } from 'react';
import { LevelProps } from '../types/props';

type WindowData = LevelProps;

interface WindowContextType {
    windowOpen:boolean;
    windowData:WindowData|null;
    openWindow:(data: WindowData) => void;
    closeWindow:() => void;
}

const WindowContext = createContext<WindowContextType|null>(null);

function DemonWindowProvider({ children }:{ children:React.ReactNode }):React.ReactElement {
    const [windowOpen, setWindowOpen] = useState(false);
    const [windowData, setWindowData] = useState<WindowData|null>(null);

    const openWindow = (data:WindowData) => {
        setWindowData(data);
        setWindowOpen(true);
    }

    const closeWindow = () => {
        setWindowData(null);
        setWindowOpen(false);
    }

    return (
        <WindowContext.Provider value={{ windowOpen, windowData, openWindow, closeWindow }}>
            {children}
        </WindowContext.Provider>
    );
}

function useDemonWindow() {
    const ctx = useContext(WindowContext);
    if (ctx === null) throw new Error('useModal must be used within a ModalProvider');
    return ctx;
}

export { DemonWindowProvider, useDemonWindow };
export type { WindowData };