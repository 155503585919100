import { Link } from "react-router-dom";
import "./Footer.scss";

export default function Footer() {
    return (
        <footer>
            <div className="container">
                <div className="footer-body">
                    <ul className="footer-columns">
                        <li className="footer-column">
                            <h3>Private server</h3>
                            <Link to="/download">Download</Link><br />
                            <Link to="/news">News</Link><br />
                            <Link to="/demonlist">Demonlist</Link><br />
                            <a href="https://gdpshub.com/gdps/2354">GDPSHub</a>
                        </li>
                        <li className="footer-column">
                            <h3>Community</h3>
                            <a href="https://discord.gg/u7CaND5Ea5">Discord</a><br />
                            <a href="https://youtube.com/@relegacyx?si=ytIonI4GmZ33X-pf">YouTube</a><br />
                            <a href="https://t.me/ReLegacyX">Telegram</a>
                        </li>
                    </ul>
                    <div className="copyright">
                        <h3>Legacy Alliance © 2024</h3>
                    </div>
                </div>
            </div>
        </footer>
    );
}