import { HashRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./utils/scrollToTop";
import { DemonWindowProvider } from "./hooks/openLevelWindow";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./views/Home/Home";
import Demonlist from "./views/Demonlist/Demonlist";
import News from "./views/News/News";
import Download from "./views/Download/Download";
import "./globals.scss";

export default function App() {
    return (
        <HashRouter>
            <DemonWindowProvider>
                <ScrollToTop />
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/demonlist" element={<Demonlist />} />
                    <Route path="/news" element={<News />} />
                    <Route path="/download" element={<Download />} />
                </Routes>
                <Footer />
            </DemonWindowProvider>
        </HashRouter>
    );
}