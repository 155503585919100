import { AdminProps } from "../../types/props";
import getRandomInt from "../../utils/getRandomInt";
import "./Admin.scss";

export default function Admin(props:AdminProps) {
    return (
        <li className="admin-item">
            <img src={`./img/admins/${props.img}.png?${getRandomInt(100, 999)}`} alt="" />
            <div className="admin-text">
                <h3>{props.username}</h3>
                <p>{props.text}</p>
            </div>
        </li>
    );
}