import { useState, useEffect } from "react";
import axios from "axios";
import NewsItem from "../../components/NewsItem/NewsItem";
import { NewsItemProps } from "../../types/props";
import getRandomInt from "../../utils/getRandomInt";
import "./News.scss";

export default function News() {
    const [posts, setPosts] = useState<Array<NewsItemProps>>([]);

    useEffect(() => {
        try {
            const req = async () => {
                const res = await axios.get('./news.txt?' + getRandomInt(100, 999));
                const lines = res.data.split('\n');
                let result:Array<NewsItemProps> = [];

                for(let i = 0; i < lines.length; i++) {
                    if(!lines[i]) continue;

                    const params = lines[i].split(';');
                    result.push({ title: params[0], text: params[1] });
                }

                setPosts(result);
            }

            req();
        } catch(e) {
            console.log(e);
        }
    }, []);
    
    return (
        <main>
            <div className="container">
                <ul className="news-list">
                    {
                        posts.length > 0 ? posts.map((post, index) => {
                            return <NewsItem title={post.title} text={post.text} key={index+'news'} />
                        }) : 'No news yet'
                    }
                </ul>
            </div>
        </main>
    );
}