export default [
    {
        title: 'Great servers',
        text: 'Our servers do an excellent job and work 24/7',
        img: 'hosting'
    },
    {
        title: 'Interesting levels',
        text: 'The creators of our server create amazing levels',
        img: 'star'
    },
    {
        title: 'Own demonlist',
        text: 'Our GDPS has its own list of hardest levels',
        img: 'learning'
    },
];