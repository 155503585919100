import { NewsItemProps } from "../../types/props";
import getRandomInt from "../../utils/getRandomInt";
import "./NewsItem.scss";

export default function NewsItem(props:NewsItemProps) {
    return (
        <li className="news-item">
            <h2>{props.title}</h2>
            {
                props.text.includes(':') ? props.text.split(':').map((par, index) => {
                    return <p>{par}</p>
                }) : <p>{props.text}</p>
            }
        </li>
    );
}