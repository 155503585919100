import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import WeareHelper from "../../utils/helpers/WeareHelper";
import WeareItem from "../../components/WeareItem/WeareItem";
import { AdminProps } from "../../types/props";
import getRandomInt from "../../utils/getRandomInt";
import Admin from "../../components/Admin/Admin";
import GDPSStat from "../../components/GDPSStat/GDPSStat";
import { GDPSStatData } from "../../types/props";
import "./Home.scss";

export default function Home() {
    const [admins, setAdmins] = useState<Array<AdminProps>>([]);
    const [GDPSData, setGDPSData] = useState<GDPSStatData>();
    const navigate = useNavigate();
    
    useEffect(() => {
        try {
            (async () => {
                const res = await axios.get('./admins.txt?' + getRandomInt(100, 999));
                const lines = res.data.split('\n');
                let result:Array<AdminProps> = [];

                for(let i = 0; i < lines.length; i++) {
                    if(!lines[i]) continue;

                    const params = lines[i].split(';');
                    result.push({ username: params[0], text: params[1], img: params[2] });
                }

                setAdmins(result);
            })();

            (async () => {
                const res1 = await axios.get('./apipath.txt?' + getRandomInt(100, 999));
                const api = res1.data;

                console.log(api + '/getStats.php')
                const res2 = await axios.get(api + '/getStats.php');
                setGDPSData(res2.data);
            })();
        } catch(e) {
            console.log(e);
        }
    }, []);

    return (
        <main>
            <div className="fscreen">
                <div className="container">
                    <div className="offer">
                        <h1>GDPS, which will show the history</h1>
                        <p>We are chosen by a huge number of players from all over the world. Try playing too</p>
                        <div className="offer-btns">
                            <button className="offer-dwnl-btn" type="button" onClick={() => navigate('/download')}>Download</button>
                            <button className="offer-ds-btn" type="button" onClick={() => window.location.href = 'https://discord.gg/u7CaND5Ea5'}>Discord</button>
                        </div>
                    </div>
                    <div className="weare">
                        <h2>Our project is...</h2>
                        <ul className="weare-list">
                            {
                                WeareHelper.map((weareItem, index) => {
                                    return <WeareItem title={weareItem.title} text={weareItem.text} img={weareItem.img} key={index+'wai'} />;
                                })
                            }
                        </ul>
                    </div>
                </div>
                <img className="about-transition-top" src="./img/home/transition-top.svg" alt="" />
            </div>
            <div className="about">
                <div className="container">
                    <div className="about-content">
                        <div className="about-content-img"></div>
                        <div className="about-content-text">
                            <h2>About</h2>
                            <p>We are a private server for the game Geometry Dash. Our players number in the hundreds, and our servers are filled with tons of good levels</p>
                            <p>Our server has existed for many months and during this time it has received many unique features</p>
                        </div>
                    </div>
                    <div className="discord">
                        <div className="discord-text">
                            <h2>Beautiful discord server</h2>
                            <p>If you are tired of building, completing levels, or you just want to relax, join us to our discord server</p>
                            <button>Join us</button>
                        </div>
                        <div className="discord-img">
                            <iframe src="https://discord.com/widget?id=1274807493629051063&theme=white" height="350" allowTransparency={true} frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts" style={{maxWidth: '100%'}}></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <div className="gdps-stats">
                <img className="download-transition-top" src="./img/home/transition-bottom.svg" alt="" />
                <div className="container">
                    <div className="gdps-stats-content">
                        <h2>GDPS stats</h2>
                        <ul className="gdps-stats-list">
                            {
                                GDPSData && (
                                    <React.Fragment>
                                        <GDPSStat title="Number of levels" count={GDPSData?.levels} />
                                        <GDPSStat title="Number of users" count={GDPSData?.users} />
                                        <GDPSStat title="Number of mappacks" count={GDPSData?.mappacks} />
                                    </React.Fragment>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="admins">
                <div className="container">
                    <h2>Our admins</h2>
                    <ul className="admins-list">
                        {
                            admins.length > 0 ? admins.map((admin, index) => {
                                return <Admin username={admin.username} text={admin.text} img={admin.img} key={index+'admin'} />;
                            }) : 'No admins yet'
                        }
                    </ul>
                </div>
            </div>
            <div className="container">
                <div className="download" id="download">
                    <div className="download-img">
                        <img src="./img/home/dwnl.png" alt="" />
                    </div>
                    <div className="download-text">
                        <h2>Download</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde eos maiores veritatis distinctio sequi magnam vero totam ipsum, expedita incidunt explicabo enim quam voluptates? Vitae nulla totam ratione voluptatibus nihil?</p>
                        <div className="dwnl-btns">
                            <a href="./ReLegacyX.apk">Download APK v1.51</a>
                            <a href="./relegacy.ipa">Download IPA v1.4</a>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}